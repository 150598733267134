import { createStore } from "vuex";
import eoiStore from "@/store/eoi/eoiStore";
import authStore from "@/store/auth/authStore";
import profileStore from "@/store/profile/profileStore";
import collegeStore from "@/store/college/collegeStore";
import collegeMandatoryCoursesStore from "@/store/college/collegeMandatoryCoursesStore";
import commonStore from "@/store/common/commonStore";
import adminStore from "@/store/admin/adminStore";
import adminReportMandatoryCourses from "@/store/admin/adminReportMandatoryCourses";
import adminReportCollege from "@/store/admin/adminReportCollege";
import adminIndustriesStore from "@/store/admin/adminIndustriesStore";
import adminEOIStore from "@/store/admin/adminEOIStore";
import industriesStore from "@/store/industries/industriesStore";
import studentStore from "@/store/student/studentStore";
import studentFSStore from "@/store/student/studentFSStore";
import studentCoursesStore from "@/store/student/studentCoursesStore";
import studentCoursesOldStore from "@/store/student/studentCoursesOldStore";
import jobsStore from "@/store/jobs/jobsStore";
import router from "@/router";
import dashboardStore from "@/store/launch/dashboardStore";
import assessmentsStore from "@/store/admin/assessments/assessmentsStore";
import trainingPartners from "@/store/admin/trainings/trainingPartners";
import studentPlacements from "@/store/student/studentPlacements";
import collegeFacultyStore from "@/store/college/collegeFacultyStore";
import collegePlacementOfficerStore from "@/store/college/collegePlacementOfficerStore";
import adminAPIClients from "@/store/admin/api-client/adminAPIClients";
import adminFeedbackStore from "@/store/admin/feedbacks/adminFeedbackStore";
import upscUserRegistrations from "@/store/admin/upsc_registration/upscUserRegistrations";
import adminInternshipsStore from "@/store/admin/internships/adminInternshipsStore";
import internshipStore from "@/store/admin/internships/adminInternship";


export default createStore({
  state: {
    isLoggedIn: false,
    isLoading: false,
    activeUser: {},
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_USER_DATA(state) {
      try {
        const user = localStorage.getItem("user");
        state.activeUser = {
          ...JSON.parse(user || "{}"),
        };

      } catch (e) {
        state.activeUser = {};
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    isLoading(state) {
      return state.isLoading;
    },
    activeUser(state) {
      return state.activeUser;
    },
  },
  actions: {
    downloadJSONToCSV(state, {data, filename}) {
      const replacer = (key: any, value: any) => value === null ? '' : value // specify how you want to handle null values here
      const header = Object.keys(data[0])
      let csv: any = data.map((row: any) => header.map((fieldName: string) =>
          JSON.stringify(row[fieldName], replacer)).join(','))
      csv.unshift(header.join(','))
      csv = csv.join('\r\n')

      // Create link and download
      const link = document.createElement('a');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadStringToCSV(state, {data, filename}) {
      // Create link and download
      const link = document.createElement('a');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(data));
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    checkLoginStatus({ commit, dispatch }) {
      const token = localStorage.getItem("token");
      if (token) {
        commit("SET_LOGGED_IN", true);
        dispatch("fetchProfileDetails");
        commit("SET_USER_DATA");
      } else {
        commit("SET_LOGGED_IN", false);
        commit("SET_USER_DATA");
      }
    },
    logout({ commit }, data) {
      const locale = localStorage.getItem("lang");
      localStorage.clear();
      if(locale) {
        localStorage.setItem("lang", locale);
      }
      commit("SET_LOGGED_IN", false);
      commit("SET_USER_DATA");
      if(!data) {
        router.push("/");
        window.location.reload();
      }
    },
  },
  modules: {
    eoiStore,
    authStore,
    profileStore,
    collegeStore,
    collegeMandatoryCoursesStore,
    commonStore,
    adminStore,
    adminReportMandatoryCourses,
    adminReportCollege,
    adminAPIClients,
    studentStore,
    studentFSStore,
    studentCoursesStore,
    studentCoursesOldStore,
    jobsStore,
    dashboardStore,
    adminIndustriesStore,
    adminEOIStore,
    industriesStore,
    assessmentsStore,
    trainingPartners,
    studentPlacements,
    collegeFacultyStore,
    collegePlacementOfficerStore,
    adminFeedbackStore,
    upscUserRegistrations,
    adminInternshipsStore,
    internshipStore,
  },
});
