import axios from "@/http/http";
import axios1 from 'axios';
import {Module, ActionTree} from "vuex";
import eoiAuth from "@/http/eoiAuth";

export const newAxios = axios1.create({
    baseURL: 'https://naanmudhalvan.tn.gov.in/'
});

const actions: ActionTree<any, any> = {

    fetchPlacementStudentsList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/placements/list/", { params: data });
                commit("setPlacementStudentsData", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },
    fetchPlacementStudentsAllList({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/placements/placement_list/");
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    fetchStudentPlacementStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/placements/details/", { params: data });
                commit("setPlacementData", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    getPlacementCompanies({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get("/api/v1/placements/companies/", { params: data });
                commit("setPlacementCompanyData", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    updateCompanyList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            
            const formData = new FormData();
            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post("/api/v1/placements/companies/", formData);
                commit("setPlacementCompanyData", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    updateEditPlacementStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post("/api/v1/placements/details/", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },

    fetchAllPlacementData({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/getPlacementDetails.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },


    addSelectedCourse({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/insertCourseSelection.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    updateSelectedCourse({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/updateSelectedCourse.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchSelectedCourses({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/fetchSelectedCourses.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchAllCourses({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await newAxios.post("/reports/api/courses/fetchAllCourses.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchBranch({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/fetchBranch.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchCompletedCount({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/fetchCompletedCount.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    fetchCollegewiseStudents({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/fetchCollegewiseStudents.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },


    downloadAllCompleted({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/test.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    downloadFullReportAdmin({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {

                const response = await newAxios.post("/reports/api/courses/downloadCourseSelectionReport.php", formData);

                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getStudentSocialLinkStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    updateSocialLinkStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/socials/updateSocialLinkStatus.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    generateSpocCertificate({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/generateCertificate.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getSpocName({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/getSpocName.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    updateSpocName({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/updateSpocName.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

    getSpocCertStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/getSpocCertStatus.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },


    updateSpocCertStatus({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                // const response = await newAxios.post("/reports/api/socials/fetchSocialLinkStatus.php", formData);
                const response = await newAxios.post("/reports/api/spoc_certificates/updateSpocCertificate.php", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    PoAttendanceMark({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            
            const formData = new FormData();
            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post("/api/v1/placements/update_attendance/", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    console.error(e);
                    reject(err.message);
                }
            }
        });
    },


    getStudentsJobFairList({ commit }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          const formData = new FormData();
          Object.keys(data).forEach((key: string) => {
            formData.append(key, data[key]);
          });
          commit("SET_LOADING", true);
          try {
            const response = await newAxios.post(`/reports/api/placements/getCollegeStudentList.php`, formData);
            commit("setStudentsJobFairList", response.data);
            resolve(response.data);
          } catch (err: any) {
            try {
              reject(err.response.data.message);
            } catch (e) {
              reject(err.message);
            }
          }
          commit("SET_LOADING", false);
        });
      },

      fetchJobFairListForStudents({ commit }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          const formData = new FormData();
          Object.keys(data).forEach((key: string) => {
            formData.append(key, data[key]);
          });
          commit("SET_LOADING", true);
          try {
            const response = await newAxios.post(`/reports/api/placements/getJobFairList.php`, formData);
            commit("setJobFairListForStudents", response.data);
            resolve(response.data);
          } catch (err: any) {
            try {
              reject(err.response.data.message);
            } catch (e) {
              reject(err.message);
            }
          }
          commit("SET_LOADING", false);
        });
      },
    
      updateEditedCgpa({ commit }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await axios.post("/api/v1/student/update_cgpa", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    updateInternshipEnrollment({ commit }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await axios.post("/api/v1/placements/update_additional_certificates", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    cancelInternshipEnrollment({ commit }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            commit("SET_LOADING", true);
            try {
                const response = await axios.delete("/api/v1/industry/delete_internship_enrollment/", {data: formData});
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },

};
const studentPlacements: Module<any, any> = {
    state: () => ({
        placementData: {},
        placementStudentsList: [],
        placementStudentsCount: 0,
        placementStudentsTotalPages: 0,
        placementStudentsLimit: 20,
        placementStudentsPage: 0,
        placedCount: 0,
    }),
    mutations: {
        setPlacementData(state: any, data: any) {
            state.placementData = data;
        },
        setPlacementStudentsData(state, data) {
            (data.data as any[]).forEach(student => {
                if (student.no_of_arrears === null) {
                    student.no_of_arrears = "" as any;
                }
            });

            state.placementStudentsList = data.data;
            state.studentsCount = data.count;
            state.placedCount = data.placed_count;
            state.aspirationCount = data.aspiration_count;
            state.placementStudentsTotalPages = Math.ceil(data.count / data.limit);
            state.placementStudentsLimit = data.limit;
            state.placementStudentsPage = data.page;
        },
        setStudentsJobFairList(state, data) {
            state.studentsJobFairList = data ? data.data[0].allStudents : [];
        },
        setJobFairListForStudents(state, data) {
            state.jobFairListForStudents = data ? data.data[0].allStudents : [];
        }
    },
    actions,
    getters: {
        studentPlacement(state: any) {
            return state.placementData;
        },
        placementStudentsList(state: any) {
            return state.placementStudentsList;
        },
        placementStudentsCount(state: any) {
            return state.studentsCount;
        },
        placementStudentsTotalPages(state: any) {
            return state.placementStudentsTotalPages;
        },
        placementStudentsLimit(state: any) {
            return state.placementStudentsLimit;
        },
        placementStudentsPage(state: any) {
            return state.placementStudentsPage;
        },
        placedStudentsCount(state: any) {
            return state.placedCount;
        },
        aspirationFilledCount(state: any) {
            return state.aspirationCount;
        },
        studentsJobFairList(state: any) {
            if (!state.studentsJobFairList || !Array.isArray(state.studentsJobFairList)) {
                return [];
            }

            const sortedList = [...state.studentsJobFairList];
            const sortByAttendanceStatus = (a: any, b: any) => {
                if (a.attendance_status === b.attendance_status) {
                    return 0;
                }
                if (a.attendance_status === "No") {
                    return -1;
                }
                return 1;
            };

            sortedList.sort(sortByAttendanceStatus);
            return sortedList;
        },
        jobFairListForStudents(state: any) {
            const currentDate = new Date().toISOString().split('T')[0];
            const jobFairs = state.jobFairListForStudents || [];

            return jobFairs.filter((jobFair: any) => {
                const jobFairDate = new Date(jobFair.start_date).toISOString().split('T')[0];
                return jobFairDate === currentDate;
            });
        },
    },
};

export default studentPlacements;
