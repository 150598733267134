import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getUniversitiesByCollegeType({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/industry/fetch_university_colltype/`,
          formData
        );
        commit("SET_UNIVERSITIES", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCollegeCategoriesByUniversity({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/industry/fetch_callcat_typeuniv/`,
          formData
        );
        commit("SET_COLLEGE_CATEGORIES", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getBranchByCollegeCategory({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/industry/fetch_branch_typeunivcat/`,
          formData
        );
        commit("SET_BRANCHES", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getSemesterByBranch({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/industry/fetch_sem_typeunivcatbr/`,
          formData
        );
        commit("SET_SEMESTERS", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCollegesForInternshipAllocation({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/industry/fetch_clg_typeunivcatbrsem/`,
          formData
        );
        commit("SET_COLLEGES_FOR_INTERNSHIP_ALLOCATION", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  saveAllocatedCollegesForInternship({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post('/api/v1/industry/add_intern_allocation/', formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};

const adminIndustriesStore: Module<any, any> = {
  state: () => ({
    universityList: [],
    collegeCategoryList: [],
    branchList: [],
    semesterList: [],
    availableCollForInternshipAlloc: [],
    allocatedCollForInternshipAlloc: [],
  }),

  mutations: {
    SET_UNIVERSITIES(state, payload) {
      state.universityList = payload.data;
    },

    SET_COLLEGE_CATEGORIES(state, payload) {
      state.collegeCategoryList = payload.data;
    },

    SET_BRANCHES(state, payload) {
      state.branchList = payload.data;
    },

    SET_SEMESTERS(state, payload) {
      state.semesterList = payload.data;
    },

    SET_COLLEGES_FOR_INTERNSHIP_ALLOCATION(state, payload) {
      state.availableCollForInternshipAlloc = payload.data.available_colleges;
      state.allocatedCollForInternshipAlloc = payload.data.selected_colleges;
    },
  },

  actions,

  getters: {
    universityList(state) {
      return state.universityList;
    },

    collegeCategoryList(state) {
      return state.collegeCategoryList;
    },

    branchList(state) {
      return state.branchList;
    },

    semesterList(state) {
      return state.semesterList;
    },

    availableCollForInternshipAlloc(state) {
      return state.availableCollForInternshipAlloc;
    },

    allocatedCollForInternshipAlloc(state) {
      return state.allocatedCollForInternshipAlloc;
    },
  },
};

export default adminIndustriesStore;
